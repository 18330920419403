import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import styled from 'styled-components';
import background from '../../assets/img/new-12.png';
import SectionContainer from '../../components/shared/SectionContainer';
import { getMarkdownText } from '../../components/utils';

const query = graphql`
  {
	strapiSavanoriaiVaikams {
		procentai {
		  antraste
		  instrukcija {
			pavadinimas
			tekstas
		  }
		  kaip_skirti
		  kaip_skirti_antraste
		  mygtukas
		  mygtukas_nuoroda
		}
	  }
  }
`
function proc12() {
	const data = useStaticQuery(query)?.strapiSavanoriaiVaikams?.procentai;

	return (
		<div>	
			<SectionContainer style={{ padding: '32px', minHeight: '700px', display: 'flex' }} src={background}>
				<Tekstas>{data.antraste}</Tekstas>
			</SectionContainer>

			<SectionContainer backgroundColor={'rgba(63, 138, 187, 0.1)'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				{data.kaip_skirti_antraste && <Header1>{data.kaip_skirti_antraste}</Header1>}
				{data.kaip_skirti && <Header2 dangerouslySetInnerHTML={getMarkdownText(data.kaip_skirti)}></Header2>}
				{data.instrukcija.map((data: any, index: number) =>
					<Laukelis key={index}>
						<Labelis>{data.pavadinimas}</Labelis>
						<Valuesas>{data.tekstas}</Valuesas>
					</Laukelis>
				)}

				<Butonas onClick={() => window.open(data.mygtukas_nuoroda)}>
					{data.mygtukas}
				</Butonas>
			</SectionContainer>
		</div>
	)
}

export default proc12;

const Laukelis = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 8px;
	background: #FFFFFF;
	border: 1px solid #0E558F;
	border-radius: 10px;

	max-width: 600px;
	width: 100%;
	margin-bottom: 16px;
`

const Labelis = styled.div`
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	letter-spacing: 0.03em;

	/* Secondary Blue */
	color: #0E558F;
`

const Valuesas = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 200%;
	/* or 40px */
	display: flex;
	align-items: center;

	color: #0E558F;
`
const Butonas = styled.button`
	padding: 12px 48px;
	gap: 10px;

	max-width: 351px;
	width: 100%;
	// height: 52px;

	background: #FF7469;
	border-radius: 12px;

	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	border: 0;
	outline none;

	/* Tertiary blue */
	color: #0A2540;

	margin: 24px 0 40px 0;
`

const Header1 = styled.div`
font-weight: 600;
font-size: 48px;
line-height: 140%;
/* or 67px */
display: flex;
align-items: center;
text-align: center;

/* Secondary Blue */
color: #0E558F;
padding: 80px 0 40px 0; 
`

const Header2 = styled.div`

	font-weight: 500;
	font-size: 24px;
	line-height: 45px;
	/* or 188% */
	display: flex;
	align-items: center;
	text-align: center;

	/* Secondary Blue */
	color: #0E558F;
	margin-bottom: 40px;

	a {
		font-weight: 600;
		cursor: pointer;
	}
`

const Tekstas = styled.div`
	position: relative;
	z-index: 5;

	font-weight: 600;
	font-size: 96px;
	line-height: 140%;
	/* or 134px */
	display: flex;
	align-items: center;
	text-align: center;

	/* White */
	color: #FFFFFF;

	@media (max-width: 900px) {
		
			font-size: 48px !important;
			text-align: center !important;
		
	}
`