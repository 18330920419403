import React from 'react'
import styled from 'styled-components';

const SectionContainer = ({ children, style, backgroundColor, src, opacityBackground}: any) => {
    return <>
        {
            !!backgroundColor && <Background style={{ background: backgroundColor, }}>
                <Container style={style}>
                    {children}
                </Container>
            </Background>
        }
        {
            !backgroundColor && !src && <Container style={style}>
                {children}
            </Container>
        }
        {

            !backgroundColor && src &&
            <div style={{
                background: `linear-gradient(0deg, rgba(10, 37, 64, ${opacityBackground ? opacityBackground : '0.7'}), rgba(10, 37, 64, ${opacityBackground ? opacityBackground : '0.7'})), url(${src})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                
            }}>
                <Container style={style}>
                    {children}
                </Container>
            </div>
        }
    </>
};

export default SectionContainer;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 1160px; 
    width: 100%;
	margin: 0 auto;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		padding: 0 16px 0;
	}

`

const Background = styled.div`

`
